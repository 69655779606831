import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from "../../commons/Theme";

export const StyledTechnological = styled.div`
  position: relative;
  background-color: ${Colors.yellow};
  padding-top: ${Rem(52)};
  padding-bottom: ${Rem(40)};
  margin-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(72)};
    padding-bottom: 0;
    margin-bottom: ${Rem(70)};
  }
`;

export const StyledTechnologicalIntro = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: ${Rem(24)};
  }
`;

export const StyledTechnologicalTitle = styled.h2`
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  margin-bottom: ${Rem(16)};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
    letter-spacing: ${Rem(5)};
    width: 30%;
  }
`;

export const StyledTechnologicalSubtitle = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  margin-bottom: ${Rem(36)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
    width: 56%;
  }
`;

export const StyledTechnologicalItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media (${BP.ipad}) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${Rem(100)};
  }
`;

export const StyledTechnologicalItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${Rem(42)};

  @media (${BP.ipad}) {
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTechnologicalItemImage = styled.div`
  position: relative;
  top: 0; 
  display: flex; 
  ${Ratio(6000, 3375)};
  width: 100%;
  margin-bottom: ${Rem(8)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media(${BP.ipad}) {
    ${Ratio(500, 500)};
    min-width: ${Rem(155)};
    max-width: ${Rem(155)};
    margin-right: ${Rem(55)};
    
    &:after {
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: ${Rem(94)};
      background-color: ${Colors.white};
      right: ${Rem(-24)};
      bottom: ${Rem(12)};
    }
  }
`;

export const StyledTechnologicalItemContent = styled.div``;

export const StyledTechnologicalItemCounter = styled.div`
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  color: ${Colors.white};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
    letter-spacing: ${Rem(5)};
    margin-bottom: ${Rem(16)};
  }
`;

export const StyledTechnologicalItemTitle = styled.p`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
`;

export const StyledTechnologicalCTA = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
`;
